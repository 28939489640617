<template>
  <section>
    <Spin v-if="busy" class="loading" />
    <!-- BEGIN panel editor toolbar -->
    <div v-if="showCustomToolbar" class="panel-toolbar no-print">
      <slot name="toolbar"></slot>
    </div>
    <!-- END panel editor toolbar -->
    <!-- BEGIN table rows per page configuration -->
    <div class="html2canvas-ignore table-config" data-html2canvas-ignore>
      <DownloadButton
        v-if="
          !printPreview &&
          mode != 'editor' &&
          tableId &&
          (panel.options.downloadXLS || panel.options.downloadCSV)
        "
        @ready="onDownloading(true)"
        @done="onDownloading(false)"
        :tableId="tableId"
        :fixRight="true"
        :downloading="downloading"
        :xls="panel.options.downloadXLS"
        :csv="panel.options.downloadCSV"
      />
      <template v-if="printPreview">
        <i
          class="fa fa-pencil clicable"
          v-if="!pageEditor"
          :title="$t('titles.items_per_page')"
          @click.stop.prevent="pageEditor = true"
        ></i>
        <div v-if="pageEditor">
          <input
            type="number"
            ref="nItemsInFirstPage"
            class="form-control text-center"
            placeholder="auto"
            v-model="nItemsInFirstPage"
          />
          <input
            type="number"
            class="form-control text-center"
            placeholder="auto"
            v-model="nItemsInRemainingPages"
          />
          <i
            class="fa fa-undo clicable"
            @click.stop.prevent="pageEditor = false"
          />
          <span class="text-bold">1</span>
          <span class="fa fa-asterisk"></span>
        </div>
      </template>
    </div>
    <!-- END table rows per page configuration -->
    <div class="display">
      <div
        v-if="panel.options.title.text"
        :style="panel.options.title.style"
        @click.stop.prevent="onTitleClicked"
        ref="title"
      >
        {{ titleValue }}
      </div>
      <div
        v-if="panel.options.subTitle.text"
        :style="panel.options.subTitle.style"
        @click.stop.prevent="onSubTitleClicked"
        ref="subtitle"
      >
        {{ subTitleValue }}
      </div>
      <SynopticSimpleTable
        v-if="ready"
        ref="table"
        @tableId="tableId = $event"
        :control="control"
        :standAlone="false"
        :offsetTop="tableOffset"
        :inlineEditor="inlineEditor"
        :datasetIdList="datasetIdList"
        :nItemsInFirstPage="nItemsInFirstPage"
        :nItemsInRemainingPages="nItemsInRemainingPages"
        @tableEvent="trigger($event)"
      />
    </div>
  </section>
</template>

<script>
import { debounce } from "lodash";
import SynopticSimpleTable from "@/components/synoptic/synoptic-simple-table.vue";
import Spin from "@/components/spin.vue";
import TableForm from "@/components/control-sidebar/property-editors/table-form.vue";
import DownloadButton from "@/components/download-button.vue";
import { isSyncEnabled } from "@/services/dashboard.js";
export default {
  name: "DashboardTablePanel",
  props: {
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  inject: {
    pageSettings: {
      from: "pageSettings",
      default: null
    }
  },
  components: {
    SynopticSimpleTable,
    Spin,
    DownloadButton
  },
  data() {
    return {
      tableOffset: 0,
      ready: false,
      pageConfiguration: {
        first: undefined,
        remaining: undefined,
        editing: false
      },
      downloading: false,
      tableId: ""
    };
  },
  computed: {
    printScale() {
      return this?.pageSettings?.scale ?? 1;
    },
    control() {
      return {
        synopticComponent: this?.panel?.options
      };
    },
    datasetIdList() {
      return this.$utils
        .distinct(this?.panel?.options?.dataSetConfig?.dataList || [])
        .map(({ data_id }) => data_id);
    },
    historyDataIdList() {
      let lst = [...this.datasetIdList];
      let sheet = this.panel.options.sheet;
      for (var c in sheet) {
        for (var r in sheet[c]) {
          if (
            sheet[c][r].data_id &&
            sheet[c][r].value.indexOf(".history.") != -1
          ) {
            lst.push(sheet[c][r].data_id);
          }
        }
      }
      return this.$utils.distinct(lst);
    },
    historyInterval() {
      return (
        (this.historyDataIdList.length &&
          this.$store.getters["history/interval"]) ||
        null
      );
    },
    intervalId() {
      return `${this?.historyInterval?.start?._d?.getTime() ??
        ""}${this?.historyInterval?.end?._d?.getTime() ?? ""}`;
    },
    busy() {
      return (this.$store.getters["history/pending"] || []).length > 0;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    showCustomToolbar() {
      return (this?.panel?.toolbar || []).length > 0;
    },
    printPreview() {
      return this?.$store?.getters?.print || false;
    },
    titleValue() {
      // example1: `Connector: ${$('4584').name}`
      // example2: `Connector: ${$('.').name}`
      let exp = this?.panel?.options?.title?.text || "";
      if (!exp) return exp;
      if (exp == "title") {
        return this.$t(exp);
      }
      let ret = this.$root.$formatter.format({ template: exp });
      return ret === "" ? exp : ret;
    },
    subTitleValue() {
      // example1: `Device: ${$('4584/4328').name}`
      // example2: `Device: ${$('./4328').name}`
      let exp = this?.panel?.options?.subTitle?.text || "";
      if (!exp) return exp;
      if (exp == "subtitle") {
        return this.$t(exp);
      }
      let ret = this.$root.$formatter.format({ template: exp });
      return ret === "" ? exp : ret;
    },
    nItemsInFirstPage: {
      set(value) {
        this._set("first", value);
      },
      get() {
        return this.pageConfiguration.first || undefined;
      }
    },
    nItemsInRemainingPages: {
      set(value) {
        this._set("remaining", value);
      },
      get() {
        return this.pageConfiguration.remaining || undefined;
      }
    },
    pageEditor: {
      set(value) {
        this.pageConfiguration.editing = value;
        if (value) {
          this.$nextTick(() => {
            this.$refs.nItemsInFirstPage.focus();
          });
        } else {
          this.pageConfiguration.first = undefined;
          this.pageConfiguration.remaining = undefined;
          this.pageConfiguration.editing = false;
        }
      },
      get() {
        return this.pageConfiguration.editing || false;
      }
    },
    inlineEditor() {
      return this.isEditing && !isSyncEnabled(this.display, this.panel.name);
    }
  },
  watch: {
    intervalId: {
      handler(n, o) {
        if (this.mode == "editor") return; // it should make use of simulated samples only
        if (
          n &&
          (n !== o || n !== (this._intervalId || "")) &&
          this.historyDataIdList.length
        ) {
          this._intervalId = n; // reactivity for large screen sequence might be corrupted
          // console.log(`\n${n}\n${o}`)
          this.fetchHistory();
        }
      },
      deep: true,
      immediate: true
    },
    isEditing: {
      handler(n) {
        if (n) {
          this.setEditingStyle();
          if (this.sidebar.name != "TableForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: TableForm
            });
            // this.$nextTick(() => {
            //   this.setSideBar();
            // });
          }
        }
      },
      immediate: true
    },
    sidebar: {
      handler(n) {
        if (this.isEditing && n && n.name == "TableForm") {
          this.trigger({
            action: "sheet:activate"
          });
        }
      },
      deep: true
    },
    busy(n, o) {
      if (o && !n) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    fetchHistory() {
      if (this.mode == "editor") return; // it should make use of simulated samples only
      if (!this.historyDataIdList.length) return; // there are no configured time based data
      this.$store.dispatch("history/fetch", this.historyDataIdList);
    },
    onTitleClicked() {
      this.trigger({
        action: "title:activate"
      });
    },
    onSubTitleClicked() {
      this.trigger({
        action: "sub_title:activate"
      });
    },
    onTableEvent(ev) {
      this.trigger(ev);
    },
    trigger(ev) {
      this.setSideBar();
      this.$nextTick(() => {
        ev.details = ev.details || {};
        if (!ev.details.panelName) {
          ev.details.panelName = this.panel.name || "";
        }
        if (!ev.details.control) {
          ev.details.control = this.control.synopticComponent;
        }
        this.$root.$emit("table:event", ev);
      });
    },
    setSideBar() {
      if (((this?.sidebar?.name || "") != "TableForm") && (this?.sidebar?.contentPanelWidget || "") != "DetailFormTable" && this.mode == "editor") {
        this.$root.$emit("controlSidebar:setContent", TableForm);
      }
    },
    setEditingStyle() {
      if (this.$el) {
        this.$el.style["z-index"] = "1";
        //this.$el.style["overflow"] = "unset";  // without it cell menu are cliped
      }
    },
    setupPrint(status) {
      if (
        this.printPreview &&
        this.printScale &&
        this?.panel?.options?.dataSetConfig?.address &&
        !this.tableOffset
      ) {
        // find any element that will not appear in this page and that is above this panel (maz)
        let panelOffsetTop = this.$el.offsetTop;
        // let hiddenPanelsHeights = 0;
        // this.$el
        //   .closest(".paper")
        //   ?.getElementsByClassName("__equipment_toolbar_panel__")
        //   .forEach((e) => {
        //     hiddenPanelsHeights +=
        //       e?.offsetTop < panelOffsetTop ? e?.clientHeight ?? 0 : 0;
        //   });
        let hiddenPanelsHeights = 44;
        let titleHeight = this.$refs.title?.clientHeight ?? 0;
        let subtitleHeight = this.$refs.subtitle?.clientHeight ?? 0;
        this.tableOffset =
          (panelOffsetTop +
            titleHeight +
            subtitleHeight -
            hiddenPanelsHeights) *
          (this.printScale ?? 1);
        // console.log(`${this.panel.options.title.text} ${this.tableOffset}`);
        return;
      }
      this.tableOffset = 0;
    },
    onDownloading(opt) {
      this.downloading = opt;
      this.topMost();
    },
    topMost() {
      setTimeout(
        () => {
          this.$el.style.overflow = "visible";
          // this.$el.style["min-height"] = "auto";
        },
        100,
        this
      );
    }
  },
  mounted() {
    if (this.isEditing) {
      this.setEditingStyle();
    } else if (this.mode != "editor") {
      if (this.printPreview && this?.panel?.options?.dataSetConfig?.address) {
        // this.$root.$on("dashboard:printing", this.setupPrint);
        this.$nextTick(() => {
          setTimeout(
            () => {
              this.setupPrint();
              this.ready = true;
              // console.log(this.tableOffset);
            },
            1000,
            this
          );
        });
        return;
      }
    }
    this.ready = true;
  },
  created() {
    this._set = debounce((p, v) => {
      if (v) {
        this.pageConfiguration[p] = parseInt(v);
      } else {
        this.pageConfiguration[p] = undefined;
      }
    }, 200);
  }
};
</script>

<style scoped>
.loading {
  position: absolute;
  width: 32px;
  top: 50%;
  left: 50%;
  z-index: 9;
}

.display {
  clear: both;
  width: 100%;
}
.panel-title {
  padding-left: 10px;
}

.panel-toolbar {
  position: absolute;
  top: 3px;
  left: 0px;
  background-color: transparent;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.table-config {
  position: absolute;
  right: 0;
  top: 0;
}

.table-config > div {
  width: 100px;
}

.table-config > i {
  margin-right: 5px;
}

.table-config > div > input {
  width: 50%;
  display: inline-block;
  background-color: transparent;
  padding: 8px 0 0 0;
}

.table-config > div > i.fa-undo {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 9pt;
  z-index: 3;
  color: #666;
}

.table-config > div > span {
  position: absolute;
  top: 0;
  left: 3px;
  z-index: 1;
  font-size: 6pt;
  color: #777;
}

.table-config > div > span:last-child {
  margin-left: 50%;
}
</style>
